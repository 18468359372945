import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Component, Suspense, lazy } from "react";
import { Spinner } from 'flowbite-react';
import Protected from './components/Protected';

const RegisterClient = lazy(() => import('./pages/clientPages/registerClient/RegisterClient'));
const ClientEmailVerification = lazy(() => import('./pages/clientPages/ClientEmailVerification'));
const CompleteClientProfile = lazy(() => import('./pages/clientPages/completeClientProfile/CompleteClientProfile'));
const CompleteTherapistProfile = lazy(() => import("./pages/completeTherapistProfile/CompleteTherapistProfile"));
const EmailVerification = lazy(() => import("./pages/EmailVerificaton"));
const Register = lazy(() => import("./pages/registerTherapist/Register"));
const Login = lazy(() => import('./pages/Login'));
const UpdatePasswordOtp = lazy(() => import('./pages/resetPassword/UpdatePasswordOtp'));
const ForgotPassword = lazy(() => import('./pages/resetPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/resetPassword/ResetPassword'));
const TherapistDashboard = lazy(() => import('./pages/dashboard/TherapistDashboard'));
const SetAvailability = lazy(() => import('./pages/dashboard/availability/SetAvailability'));
const Calendar = lazy(() => import('./pages/dashboard/calendar/Calendar'));
const CreateIntakeForm = lazy(() => import('./pages/dashboard/intakeForms/CreateIntakeForm'));
const ListIntakeForms = lazy(() => import('./pages/dashboard/intakeForms/ListIntakeForms'));
const EditIntakeForm = lazy(() => import('./pages/dashboard/intakeForms/EditIntakeForm'));

function App() {
  return (
    <div className="App h-screen flex justify-center items-center p-2 mb:p-0">
      <Suspense className='h-screen flex justify-center text-center items-center' fallback={<Spinner size='xl'/>} >
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/email-verification/:therapistId' element={<EmailVerification />} />
            <Route path='/complete-profile/:therapistId' element={<CompleteTherapistProfile />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/verify-reset-password-otp/:therapistId' element={<UpdatePasswordOtp />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/dashboard' element={<Protected Component={TherapistDashboard} />} />
            <Route path='/availability' element={<Protected Component={SetAvailability} />} />
            <Route path='/calendar' element={<Protected Component={Calendar} />} />
            <Route path='/intake-form/create' element={<Protected Component={CreateIntakeForm} />} />
            <Route path='/intake-form/edit/:intakeFormId' element={<Protected Component={EditIntakeForm} />} />
            <Route path='/intake-forms' element={<Protected Component={ListIntakeForms} />} />
            
            <Route path='/invite/:code' element={<RegisterClient />} />
            <Route path='/client/email-verification/:clientId' element={<ClientEmailVerification />} />
            <Route path='/additional-information/:clientId' element={<CompleteClientProfile />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
